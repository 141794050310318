.cookies{
    min-height: 100vh;
    margin-top: 100px;
}

@media screen and (min-width: 768px) {
    .cookies{
        margin-top: 50px;
    }
}

.cookies-title{
    margin-top: 100px;
    margin-bottom: 100px;
    color: rgba(2, 33, 121, 255);
}

.cookies-text{
    margin-top: 50px;
    margin-bottom: 50px;
    color: rgba(2, 33, 121, 255);
}