/* 

Fonts 

*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');



/*

RESET

*/


/* makes sizing simpler */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* remove default spacing */
/* force styling of type through styling, rather than elements */

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* dark mode user-agent-styles */

html {
  color-scheme: dark light;
}

/* min body height */

body {
  min-height: 100vh;
}

/* responsive images/videos */
img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

/* remove list styles */

ol,
ul {
  list-style: none;
}

/* remove link styles */

a {
  text-decoration: none;
  color: inherit;
}

/* remove button styles */

button {
  border: none;
  background: none;
  cursor: pointer;
}


/*

BASE

*/

/* set body background color to white */

h1 {
  font-size: 46px;
  font-weight: bold;
  color: rgba(2, 33, 121, 255);
  text-align: center;
}

h2 {
  font-size: 30px;
  font-weight: bold;
  color: rgba(2, 33, 121, 255);
  text-align: center;
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 56px;
  }
  h2 {
    font-size: 38px;
  }
}

body {
  background-color: white;
}

/* set bghome.png form images folder in public folder as background image for the app component, the image fits the screen */

.App {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
}

.app-bg {
  background-image: url(images/bghome.png);
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  position: fixed;
  transition: all 1.5s ease-in-out;
}

.app-bg-faded {
  opacity: 0.2;
}

#itforalllogo{
  
}

/* image animation */

.itforalllogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 1s ease-in-out;
  z-index: 2;
  width: 362px;
  height: 159px;
}

.itforalllogo-top {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 241px;
  height: 106px;
}


