.about {
    position: relative;
    min-height: 100vh;
    height: fit-content;
    width: 90%;
    margin-top: 300px;
    margin-left: 5vw;
    margin-right: 5vw;
    transition: 1s ease-in;
    transition-delay: 1s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .about {
        width: 70%;
        margin-top: 200px;
        margin-left: 15vw;
        margin-right: 15vw;
    }
}

.about-image{
    margin-top: 100px;
    margin-bottom: 50px;
    height: 340px;
    width: 300px;
    border-radius: 50%;
}

.about-text {
    margin-top: 50px;
    font-size: 20px;
    color: rgba(2, 33, 121, 255);
    margin-left: 5vw;
    margin-right: 5vw;
    position: relative;
    line-height: 150%;
}

.contact-data{
    text-decoration: underline;
}

@media screen and (min-width: 768px) {
    .about-text {
        font-size: 26px;
    }
}