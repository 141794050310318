.Contact {
    position: relative;
    min-height: 100vh;
    width: 90%;
    margin-top: 300px;
    transition: 1s ease-in;
    transition-delay: 1s;
}

/* media query for screen width bigger than 768px */
@media screen and (min-width: 768px) {
    .Contact {
        width: 70%;
        margin-top: 200px;
    }
}

.contact-title{
    font-size: 38px;
}

@media screen and (min-width: 768px) {
    .contact-title{
        font-size: 56px;
    }
}

.Contact-container {
    position: relative;
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contact-data{
    scale: 1;
    transition: all 0.3s ease-in;
}

.contact-data:hover{
    scale: 1.1;
}

p{
    font-size: 20px;
}

a{
    font-weight: bold;
}

p, a{
    margin-top: 10px;
}



.earth{
    width: 30%;
    height: 30%;
}

.Contact-text {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: rgba(2, 33, 121, 255);
}

.Contact-text-container{
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .Contact-text {
        width: 60%;
    }
    p{
        font-size: 26px;
    }
    
    p, a{
        margin-top: 12px;
    }
}

.Contact-form{
    margin-top: 80px;
}


.Contact-form-container-input {
    position: relative;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    color: rgba(2, 33, 121, 255);
    flex-wrap: wrap;
    background-color: white;
    border:rgba(2, 33, 121, 255) solid 2px;
    padding: 5px;
    padding-bottom: 35px;
    border-radius: 5px;
}

.Label-and-input {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 95%;
    margin-top: 20px;
}

input, textarea{
    margin-top: 10px;
    width: 100%;
    padding: 5px;
    background-color: rgb(240, 240, 240);
    border-radius: 5px;
    border: rgba(2, 33, 121, 255) solid 2px;
    color: rgba(2, 33, 121, 255);
    font-size: 16px;
}

.form-send{
    margin-top: 30px;
    padding: 5px;
    background-color: rgb(240, 240, 240);
    border: rgba(2, 33, 121, 255) solid 2px;
    border-radius: 5px;
    color: rgba(2, 33, 121, 255);
    font-size: 20px;
    min-width: 20%;
}

@media screen and (min-width: 768px) {
    .Contact-form-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .Contact-form-container-input {
        width: 60%;
    }
    .Label-and-input {
        width: 90%;
    }
    input, textarea{
        font-size: 20px;
    }
    .form-send{
        font-size: 24px;
    }
}
    