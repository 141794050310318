.procedures {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 95%;
    margin-top: 200px;
    transition: 1s ease-in;
    transition-delay: 1s;
}

/* media query for screen width bigger than 768px */
@media screen and (min-width: 768px) {
    .procedures {
        width: 70%;
        margin-top: 150px;
    }
}

.procedure-title-and-arrow{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
    width: 50%;
    border: solid 2px rgba(2, 33, 121, 255);
}

.arrow{
    cursor: pointer;
    height: 40px;
    width: 40px;
}

.inverted-arrow{
    cursor: pointer;
    height: 40px;
    width: 40px;
    transform: rotate(180deg);
}

.pdf-container {
    overflow: hidden;
    width: 100%; /* or a specific width */
    height: auto; /* or a specific height */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pdf-page {
    width: fit-content;
    height: auto;
    margin-bottom: 20px;
}

/*screen smaller than 768px*/
@media (max-width: 768px) {
    .pdf-container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .pdf-page {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
}