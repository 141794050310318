.home-link{
    margin: 0;
    margin-bottom: 20px;
}

.Navbar {
    margin-top: 250px;
    width: 600px;
    color: rgba(2,33,121,255);
    z-index: 1;
}

.navbar-menu-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
}

.navbar-menu-list-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    z-index: 2;
    border-bottom: rgb(2,33,121) 2px solid;
}

/* create an animation that underlines the menu item when hovered over*/

.navbar-menu-link:hover {
    border-bottom: rgb(2,33,121) 2px solid;
}

.menu-icon {
    position: fixed;
    z-index: 2;
    top: 15px;
    left: 15px;
    height: 40px;
    width: 40px;
    filter: invert(100%);
}

.mobile-Navbar {
    position: fixed;
    width: 100%;
    height: 100vh;
    color: rgba(2,33,121,255);
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
}

.itforalllogo-navbar{
    height: 60px;
    /* width respect to height */
    width: 136px;
}

.itforalllogo-mobile-navbar {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 241px;
    height: 106px;
  }

.mobile-navbar-menu-list {
    display: flex;
    height: 30vh;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 26px;
    font-weight: bold;
}

/* navbar animation */

.Navbar-transition-appear {
    opacity: 0;
    z-index: 1;
}

.Navbar-transition-appear-active {
    opacity: 1;
    transition: 1s ease-in;
    transition-delay: 1s;

    z-index: 1;
}

.Navbar-transition-appear-done {
    opacity: 1;
    z-index: 1;
}

/* mobile navbar animation */

.mobile-Navbar-transition-appear {
    display: flex;
    opacity: 0;
    z-index: 1;
}

.mobile-Navbar-transition-appear-active {
    opacity: 1;
    transition: 0.3s ease-in;
    display: flex;
    z-index: 1;
}

.mobile-Navbar-transition-appear-done {
    opacity: 1;
    display: flex;
    z-index: 1;
}

.mobile-Navbar-transition-enter {
    display: flex;
    opacity: 0;
    z-index: 1;
}

.mobile-Navbar-transition-enter-active {
    opacity: 1;
    transition: 0.2s ease-in;
    display: flex;
    z-index: 1;
}

.mobile-Navbar-transition-enter-done {
    opacity: 1;
    display: flex;
    z-index: 1;
}

.mobile-Navbar-transition-exit {
    opacity: 1;
    display: flex;
}

.mobile-Navbar-transition-exit-active {
    opacity: 0;
    display: flex;
    transition: 0.2s ease-in;
}

.mobile-Navbar-transition-exit-done {
    opacity: 0;
    display: none;
}

.menu-icon-transition-enter {
    opacity: 0;
    transform: translateY(-10px);
}

.menu-icon-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease-in, transform 0.3s ease-in;
}

.menu-icon-transition-exit {
    opacity: 1;
    transform: translateY(0);
}

.menu-icon-transition-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease-in, transform 0.3s ease-in;
}