.footer{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    min-height: 80px;
    width: 100%;
    color: white;
    margin-top: 100px;
    background-color: rgba(2, 33, 121, 255);
}

.footer-text{
    font-weight: normal;
    font-size: 16px;
}

@media screen and (min-width: 768px) {
    .footer{
        min-height: 100px;
    }
    .footer-text{
        font-size: 20px;
    }
}
    