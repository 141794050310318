.Tarifs {
    position: relative;
    min-height: 100vh;
    width: 90%;
    margin-top: 300px;
    transition: 1s ease-in;
    transition-delay: 1s;
}

/* media query for screen width bigger than 768px */
@media screen and (min-width: 768px) {
    .Tarifs {
        width: 70%;
        margin-top: 200px;
    }
}

.Tarifs-container {
    position: relative;
    margin-top: 80px;
    width: 100%;
    overflow-x: scroll;
}

@media screen and (min-width: 768px) {
    .Tarifs-container {
        overflow-x: hidden;
    }
}


.Tarifs-table {
    position: relative;
    /* width: 1240px;
    height: 728px;
    max-width: none; */
}

.Offer{
    color: rgba(2, 33, 121, 255);
    margin-top: 30px;
    font-size: 20px;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .Tarifs-table {
        width: 100%;
        height: auto;
    }

    .Tarifs-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
    