.Home {
    position: relative;
    min-height: 100vh;
    width: 90%;
    margin-top: 300px;
    opacity: 0;
    transition: 1s ease-in;
    transition-delay: 1s;
}

/* media query for screen width bigger than 768px */
@media screen and (min-width: 768px) {
    .Home {
        width: 70%;
        margin-top: 200px;
    }
}

.Home-displayed {
    opacity: 1;
}

.hero {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero-text2 {
    font-size: 20px;
    color: rgba(2, 33, 121, 255);
    margin-top: 50px;
    position: relative;
}

@media screen and (min-width: 768px) {
    .hero-text2 {
        font-size: 26px;
    }
}

.home-services, .mobile-home-services {
    position: relative;
    margin-top: 50px;
}

@media screen and (min-width: 768px) {
    .home-services{
        margin-top: 250px;
    }
}

.home-services {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.home-services-subtitle{
    text-align: left;
    margin-bottom: 20px;
}

.home-services-text-wrapper{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.home-services-text {
    font-size: 22px;
    color: rgba(2, 33, 121, 255);
    text-align: left;
    line-height: 150%;
}

.mobile-home-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#cogwheel, #laptop, #headset{
    height: 200px;
    width: 200px;
    margin-top: 50px;
}
@media screen and (min-width: 768px) {
    #cogwheel, #laptop, #headset{
        height: 400px;
        width: 400px;
    }
}

.mobile-home-services-text{
    margin-top: 50px;
    font-size: 20px;
    color: rgba(2, 33, 121, 255);
}